import React, { Component } from "react";
import { Redirect } from "react-router";
//import Axios from './axios';
import { HashRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import jwt_decode from "jwt-decode";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));

const ClientPaspor = React.lazy(() => import("./views/pages/laporan/client_paspor"));
const CoverPaspor = React.lazy(() => import("./views/pages/laporan/cover_paspor"));
const PermohonanPerpanjangan = React.lazy(() =>
  import("./views/pages/laporan/permohonan_perpanjangan")
);

const Perdim = React.lazy(() => import("./views/pages/laporan/perdim"));
const Perdim23 = React.lazy(() => import("./views/pages/perdim/perdim23"));
const Perdim24 = React.lazy(() => import("./views/pages/perdim/perdim24"));
const Perdim25 = React.lazy(() => import("./views/pages/perdim/perdim25"));
const Perdim27 = React.lazy(() => import("./views/pages/perdim/perdim27"));
const CV = React.lazy(() => import("./views/pages/perdim/cv"));

const ClientStatusMonthly = React.lazy(() =>
  import("./views/pages/laporan/client_status_monthly")
);

const PrintExtensionDailyRecap = React.lazy(() =>
  import("./views/pages/laporan/print_extension_daily_recap")
);

const CetakPembayaran = React.lazy(() =>
  import("./views/pages/laporan/cetak_pembayaran")
);

const CetakRefund = React.lazy(() => import("./views/pages/laporan/cetak_refund"));

const CetakProsesKitas = React.lazy(() =>
  import("./views/pages/proses_kitas/cetak_proses_kitas")
);

const FormPassportDelivery = React.lazy(() =>
  import("./views/pages/passport_delivery/form")
);

const PhotoPassportDelivery = React.lazy(() =>
  import("./views/pages/passport_delivery/photo")
);

const Message = React.lazy(() => import("./views/pages/utilities/message"));

class App extends Component {
  render() {
    function isLogged() {
      if (localStorage.hasOwnProperty("token")) {
        let token = localStorage.getItem("token");
        let decodedToken = jwt_decode(token);
        let currentDate = new Date();

        let isValid;
        decodedToken.exp * 1000 < currentDate.getTime()
          ? (isValid = false)
          : (isValid = true);

        return isValid;
      } else {
        return false;
      }
    }

    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/passport_delivery/form/:id"
              name="Pick Up & Delivery"
              render={(props) => <FormPassportDelivery {...props} />}
            />
            <Route
              exact
              path="/passport_delivery/photo/:id"
              name="Pick Up & Delivery"
              render={(props) => <PhotoPassportDelivery {...props} />}
            />
            <Route
              exact
              path="/message"
              name="Message"
              render={(props) => <Message {...props} />}
            />

            <Route
              path="/client_paspor/:id"
              name="ClientPaspor"
              render={(props) => {
                if (isLogged()) {
                  return <ClientPaspor {...props} />;
                } else {
                  return <Redirect to="/login" />;
                }
              }}
            />

            <Route
              path="/gambar_cover_paspor/:id"
              name="CoverPaspor"
              render={(props) => {
                if (isLogged()) {
                  return <CoverPaspor {...props} />;
                } else {
                  return <Redirect to="/login" />;
                }
              }}
            />
            <Route
              path="/permohonan_perpanjangan/:id/:imigrasi/:tanggal/:alamat_penjamin/:alamat_tinggal"
              name="PermohonanPerpanjangan"
              render={(props) => {
                if (isLogged()) {
                  return <PermohonanPerpanjangan {...props} />;
                } else {
                  return <Redirect to="/login" />;
                }
              }}
            />

            <Route
              path="/formulir_perpanjangan/:id/:imigrasi/:tanggal/:alamat_penjamin/:alamat_tinggal"
              name="Perdim"
              render={(props) => {
                if (isLogged()) {
                  return <Perdim {...props} />;
                } else {
                  return <Redirect to="/login" />;
                }
              }}
            />

            <Route
              path="/client_status_monthly/:tahun/:bulan/:tipe"
              name="ClientStatusMonthly"
              render={(props) => {
                if (isLogged()) {
                  return <ClientStatusMonthly {...props} />;
                } else {
                  return <Redirect to="/login" />;
                }
              }}
            />

            <Route
              path="/print_extension_daily_recap/:start_date/:end_date/:id_kanim"
              name="PrintExtensionDailyRecap"
              render={(props) => {
                if (isLogged()) {
                  return <PrintExtensionDailyRecap {...props} />;
                } else {
                  return <Redirect to="/login" />;
                }
              }}
            />

            <Route
              exact
              path="/whatsapp"
              render={() =>
                (window.location.href =
                  "web.whatsapp.com/send?phone=628563754484&text=Halo%20saya%20ingin")
              }
            />

            <Route
              path="/cetak_pembayaran/:start_date/:end_date/:id_kategori_pembayaran/:id_user"
              name="Cetak"
              render={(props) => {
                if (isLogged()) {
                  return <CetakPembayaran {...props} />;
                } else {
                  return <Redirect to="/login" />;
                }
              }}
            />

            <Route
              path="/cetak_refund/:start_date/:end_date"
              name="CetakRefund"
              render={(props) => {
                if (isLogged()) {
                  return <CetakRefund {...props} />;
                } else {
                  return <Redirect to="/login" />;
                }
              }}
            />

            <Route
              path="/cetak_proses_kitas/:start_date/:end_date/:id_kanim/:id_agen/:nama_kanim/:nama_agen"
              name="Cetak"
              render={(props) => {
                if (isLogged()) {
                  return <CetakProsesKitas {...props} />;
                } else {
                  return <Redirect to="/login" />;
                }
              }}
            />

            <Route
              path="/perdim23/:id"
              name="Perdim23"
              render={(props) => {
                if (isLogged()) {
                  return <Perdim23 {...props} />;
                } else {
                  return <Redirect to="/login" />;
                }
              }}
            />

            <Route
              path="/perdim24/:id"
              name="Perdim24"
              render={(props) => {
                if (isLogged()) {
                  return <Perdim24 {...props} />;
                } else {
                  return <Redirect to="/login" />;
                }
              }}
            />

            <Route
              path="/perdim25/:id"
              name="Perdim25"
              render={(props) => {
                if (isLogged()) {
                  return <Perdim25 {...props} />;
                } else {
                  return <Redirect to="/login" />;
                }
              }}
            />

            <Route
              path="/perdim27/:id"
              name="Perdim27"
              render={(props) => {
                if (isLogged()) {
                  return <Perdim27 {...props} />;
                } else {
                  return <Redirect to="/login" />;
                }
              }}
            />

            <Route
              path="/cv/:id"
              name="CV"
              render={(props) => {
                if (isLogged()) {
                  return <CV {...props} />;
                } else {
                  return <Redirect to="/login" />;
                }
              }}
            />

            <Route
              path="/"
              name="Home"
              render={(props) => {
                if (isLogged()) {
                  return <TheLayout {...props} />;
                } else {
                  return <Redirect to="/login" />;
                }
              }}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
